@font-face {
  font-family: "Quicksand-Medium.tff";
  src: url("./fonts/Quicksand-Medium.ttf") format("truetype");
  /* Add additional font file types here if applicable */
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Quicksand-Medium.tff", sans-serif;
  /* Replace sans-serif with a fallback font if needed */
}
